<template>
  <div>
    <b-button
      style="
        display: block;
        margin: 100px auto;
        width: 30%;
        height: 100px;
        font-size: 1.8rem;
      "
      variant="success"
      @click="verify"
      >Account verifizieren</b-button
    >
    <b-modal
      @ok="toLogin()"
      id="verifySuccess"
      centered
      ok-only
      title="Erfolgreich"
      header-bg-variant="success"
      ok-variant="primary"
      footer-bg-variant="success"
      body-bg-variant="success"
      footer-border-variant="light"
      header-border-variant="light"
    >
      Account erfolgreich verifiziert!
    </b-modal>
    <b-modal
      id="verifyError"
      centered
      ok-only
      title="Fehler"
      header-bg-variant="danger"
      ok-variant="warning"
      footer-bg-variant="danger"
      body-bg-variant="danger"
      footer-border-variant="light"
      header-border-variant="light"
    >
      {{ response.message }}
    </b-modal>
  </div>
</template>

<script>
import dataService from "../services/data.service";
export default {
  data() {
    return {
      response: {},
    };
  },
  methods: {
    verify() {
      dataService
        .verifyAccount(
          this.$route.query.id,
          this.$route.query.verification_code
        )
        .then((response) => {
          this.response = response;
          if (response.success) {
            this.$bvModal.show("verifySuccess");
          } else {
            this.$bvModal.show("verifyError");
          }
        });
    },
    toLogin() {
      this.$router.push("/Login");
    },
  },
};
</script>

<style></style>
